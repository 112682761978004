import styled from 'styled-components';

interface Props {
  $isVisible: boolean;
}

export const StyledChildren = styled.div<Props>`
  position: relative;
  transition: all 0.05s ease-in-out;
  z-index: 1;

  ${({ $isVisible }) => `
    opacity: ${($isVisible && 1) || 0};
    visibility: ${($isVisible && 'visible') || 'hidden'};
    pointer-events: ${($isVisible && 'all') || 'none'};
    transition: opacity .05s ease-in-out,
                visibility .0s .05s;
  `}
`;
