import { type Category, getTags } from '@koala/sdk/v4';
import { useQuery } from '@tanstack/react-query';
import { ERROR_MESSAGES } from '@/constants/events';
import { useConfigOverride } from '@/redux/cmsConfig';
import { createHttpClient } from '@/services/client';
import { type RootState } from '@/types/app';
import { __DANGEROUS_EMPTY_REQUEST_ORIGIN, getOrigin } from '@/utils';
import { prepareErrorMessage } from '@/utils/global';
import { fireKAnalyticsError } from '@/utils/koalaAnalytics';
import { filterTagsByUnavailableProducts } from '@/utils/menu';

async function logFoodHallError(error: unknown) {
  const errorResponse = await prepareErrorMessage('There was an error fetching tags:', error);

  fireKAnalyticsError(ERROR_MESSAGES.FETCH_TAGS_ERROR, error, errorResponse);
}

export function useFoodHalls(menuCategories: Category[] = []) {
  let origin: string;

  if (typeof window !== 'undefined') {
    origin = getOrigin(window.location.host);
  } else {
    origin = __DANGEROUS_EMPTY_REQUEST_ORIGIN;
  }

  const client = createHttpClient({ origin });
  const { food_hall: supportsFoodHalls } = useConfigOverride('admin');

  const { data, error } = useQuery({
    queryKey: ['food-halls'],
    queryFn: () => getTags({ type: 'filter' }, { client }),
    enabled: supportsFoodHalls,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    keepPreviousData: true,
  });

  if (error) {
    void logFoodHallError(error);
  }

  return filterTagsByUnavailableProducts(data ?? [], menuCategories);
}

export const foodHallsSliceSelector = (state: RootState) => state.app.tags;
