import { useEffect, useRef, useState } from 'react';
import { StyledChildren } from './styles';

interface Props {
  onHide?: () => void;
  onShow?: () => void;
  options?: IntersectionObserverInit;
  disabled?: boolean;
  style?: Record<string, string | boolean | number>;
}

export const HideOnScroll: React.FC<Props> = ({
  onHide,
  onShow,
  children,
  options = {},
  disabled = false,
  style = {},
}) => {
  const childrenContainer = useRef<HTMLDivElement>();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleShow = () => {
    setIsVisible(true);
    onShow && onShow();
  };

  const handleHide = () => {
    setIsVisible(false);
    onHide && onHide();
  };

  useEffect(() => {
    if (!!window?.IntersectionObserver) {
      const observer = new IntersectionObserver((entries, _observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !disabled) {
            handleShow();
          } else {
            handleHide();
          }
        });
      }, options);

      childrenContainer?.current && observer.observe(childrenContainer.current);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <StyledChildren
      $isVisible={disabled ? true : isVisible}
      // @ts-expect-error ref mismatch
      ref={childrenContainer}
      style={style}
    >
      {children}
    </StyledChildren>
  );
};
