import { type OrderDetails } from '@koala/sdk';
import { useEffect, useRef, useState } from 'react';
import {
  StyledReorderList,
  StyledReorderHeading,
  StyledCollapseButton,
  StyledCollapsibleContainer,
  ReorderListPadding,
} from './styles';
import StringAccessor from '@/components/cmsConfig/stringAccessor';
import { ReorderListItem } from '@/components/reorders/listItem';
import { ViewAllReordersLink } from '@/components/reorders/viewAll';
import { HistoryIconSvg } from '@/components/uielements/historyIcon';
import { Stack } from '@/components/uielements/stack/stack';
import { useDispatch, useSelector } from '@/redux';
import meActions from '@/redux/me/actions';
import { deriveOrdersByLocationID } from '@/utils/orders';

interface Props {
  locationID?: number;
  ordersToDisplay?: number;
  collapsible?: boolean;
  showIcon?: boolean;
}

export const ReorderList = ({
  locationID,
  ordersToDisplay = 3,
  collapsible = true,
  showIcon = true,
}: Props) => {
  const pastOrders = useSelector((state) => state.app.me.pastOrders);
  const orderCompleted = useSelector((state) => state.app.basket.orderCompleted);
  const dispatch = useDispatch();
  const [listCollapse, setListCollapse] = useState(false);
  const reorderListRef = useRef(null);

  useEffect(() => {
    if (orderCompleted) {
      dispatch(meActions.fetchMePastOrders());
    }
  }, [orderCompleted]);

  /**
   * Hydrate the reorder modal details and toggle the reorder modal open
   *
   * @param orderDetails OrderDetails
   */
  const hydrateAndToggleReorderModal = (orderDetails: OrderDetails): void => {
    dispatch(meActions.fetchMeOrderDetailsSuccess(orderDetails));
    dispatch(meActions.toggleReorderReceipt(true));
  };

  /**
   * Handle the collapse chevron button click
   */
  const handleCollapseClick = (): void => {
    setListCollapse(!listCollapse);
  };

  const reorders = deriveOrdersByLocationID(pastOrders, locationID).slice(0, ordersToDisplay);

  // Triple check we have reorders to display before showing the reorder interface
  // (This is applicable in scenarios where a user has past orders but none for a specific store)
  if (!reorders.length) {
    return null;
  }

  const ariaControlsID = 'recent-orders';

  return (
    <div ref={reorderListRef} className="koala__ui-reorders-container-div">
      <Stack overrides={{ margin: 'var(--size-4) 0' }}>
        {showIcon && <HistoryIconSvg />}
        <StringAccessor tag={StyledReorderHeading} accessor="reorders.recent_orders_title" />
        <ViewAllReordersLink expand={collapsible} />
        {collapsible && (
          <StyledCollapseButton
            onClick={() => handleCollapseClick()}
            invert={listCollapse}
            aria-expanded={!listCollapse}
            aria-controls={ariaControlsID}
            id={`controls-${ariaControlsID}`}
          />
        )}
      </Stack>

      <StyledCollapsibleContainer
        collapse={listCollapse}
        role="region"
        tabIndex={-1}
        id={ariaControlsID}
      >
        <StyledReorderList>
          <ReorderListPadding />
          {reorders.map((order, i) => (
            <ReorderListItem
              orderDetails={order}
              key={`${order.id}-${i}`}
              showReorderReceipt={hydrateAndToggleReorderModal}
            />
          ))}
          <ReorderListPadding />
        </StyledReorderList>
      </StyledCollapsibleContainer>
    </div>
  );
};
