import styled from 'styled-components';
import { COLORS } from '@/constants/styles';
import { getFontStyles } from '@/redux/cmsConfig/utils';

export const StyledHeroMenuSearch = styled.div`
  div input {
    width: 320px;
  }
`;

export const StyledMenuLayoutHeader = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: ${({ theme }) =>
      theme.menu?.menu_search || theme.menu?.dietary_preferences_enabled ? 'flex' : 'none'};
    justify-content: flex-end;
  }
`;

export const StyledMobileMenuSearch = styled.div<{ $foodHallEnabled: boolean }>`
  background-color: ${({ theme }) => theme.store_detail.mobile_background_color};
  padding: var(--size-3) var(--size-4);

  @media (min-width: 768px) {
    display: none;
  }

  ${({ $foodHallEnabled }) =>
    $foodHallEnabled &&
    `
    border-top: 1px solid ${COLORS.GRAY};
  `}
`;

/**
 * !!HACK!!
 * The `BottomStoreBanner` and `TopStoreBanner` wrappers override the store
 * banner's positioning based on screen size to prevent a collision with
 * the "item added to cart" toast. Because the StoreBanner is included in
 * layout flow, it's actually rendered twice for "config placement bottom"
 * and the visibility is handled by the media query below.
 * - On desktop with config placement bottom, show on the bottom
 * - On mobile with config placement bottom, show on the top
 * - On desktop with config placement top, show on the top
 * - On mobile with config placement top, show on the top
 *
 * In the future, the banner should only be rendered once and positioning should
 * be handled by a CSS Grid `gridArea` so that it can be moved in the layout
 * structure without changing DOM positioning.
 */
export const BottomStoreBanner = styled.div`
  --bottom-store-banner__display: none;

  @media (min-width: 768px) {
    --bottom-store-banner__display: block;
  }

  bottom: 0;
  display: var(--bottom-store-banner__display);
  position: sticky;
`;

export const TopStoreBanner = styled.div`
  ${({ theme }) => `
   --top-store-banner__display: block;

   @media (min-width: 768px) {
     --top-store-banner__display: ${theme.store_banner?.placement === 'bottom' ? 'none' : 'block'}
   }

  display: var(--top-store-banner__display);
 `}
`;

export const StyledStoreReorders = styled.div(({ theme }) => ({
  ul: {
    '@media (min-width: 1025px)': {
      marginInlineEnd: 'var(--neg-size-7)',
    },
  },
  h2: {
    ...getFontStyles(theme.menu_category_header.font, ['color']),
  },
  a: {
    ...getFontStyles(theme.menu_category_header.secondary_font, ['font_size']),
  },
}));
