import { type PopularProductsOffer } from '@koala/sdk';
import { getPopularProducts } from '@koala/sdk/v4';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from '@/redux';
import { createHttpClient } from '@/services/client';
import { __DANGEROUS_EMPTY_REQUEST_ORIGIN, getOrigin } from '@/utils';
import { augmentMenuProduct } from '@/utils/menu';

/**
 * An api segment hook for fetching popular items for a specified location
 * ```
 * const { response, count, title, getProduct } = usePopularItems({ location: 1 });
 * ```
 */
export function usePopularItems({
  location,
  wantedAt,
  maxItems = 15,
}: {
  location: number | null;
  wantedAt?: string;
  maxItems?: number;
}) {
  const { popular_products: showPopularItems } = useSelector(
    (state) => state.app.cmsConfig.webConfig.menu,
  );

  const { popular_products_header: popularItemsHeader } = useSelector(
    (state) => state.app.cmsConfig.strings.store,
  );

  /**
   * Make sure that `usePopularItems` is prefetched before
   * this component renders, otherwise it will explode!
   * @see __DANGEROUS_EMPTY_REQUEST_ORIGIN
   */
  let origin: string;

  if (typeof window !== 'undefined') {
    origin = getOrigin(window.location.host);
  } else {
    origin = __DANGEROUS_EMPTY_REQUEST_ORIGIN;
  }

  const response = useQuery({
    queryKey: [
      'popular-items',
      {
        id: location ?? 0,
        wantedAt: wantedAt ?? 'asap',
      },
    ],
    queryFn: () =>
      getPopularProducts(
        { locationId: location ?? 0, maxItems, wantedAt },
        { client: createHttpClient({ origin }) },
      ),
    enabled: showPopularItems && location !== null,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    keepPreviousData: true,
  });

  return {
    response,
    count: response?.data?.products?.length ?? 0,
    title: popularItemsHeader,
    enabled: showPopularItems,
    getItem: function (product: PopularProductsOffer) {
      // popular products return a product array of 1 product
      return augmentMenuProduct(product.products[0]);
    },
  };
}
