import { CONVEYANCE_TYPES } from '@koala/sdk';
import { useState, useEffect } from 'react';
import { StyledStoreBanner, StyledStoreBannerContent } from './styles';
import StringAccessor from '@/components/cmsConfig/stringAccessor';
import { DATE_FORMAT } from '@/constants/dates';
import { STORE_BANNER_DISPLAY } from '@/constants/menu';
import { useSelector } from '@/redux';
import { useConfigOverride } from '@/redux/cmsConfig';
import { getSanitizedOffset } from '@/utils/dates';
import { determineFulfillment } from '@/utils/fulfillment';
import {
  isStoreCurrentlyOpen,
  deriveFormattedDateTimeString,
  getNextHourSlots,
  getOperatingHoursForLocation,
} from '@/utils/locations';

export const StoreBanner = () => {
  const deliveryAddress = useSelector((state) => state.app.conveyanceMode);
  const { fulfillment: basketFulfillment, location: basketLocation } = useSelector(
    (state) => state.app.basket,
  );
  const locationDetail = useSelector((state) => state.app.locations.detail);
  const { display } = useConfigOverride('store_banner');

  const [bannerVisibility, setBannerVisibility] = useState<boolean>(false);
  const [nextOpenHours, setNextOpenHours] = useState<string | null>(null);
  const [orderMode, setOrderMode] = useState<CONVEYANCE_TYPES>(CONVEYANCE_TYPES.PICKUP);

  const setBannerContent = () => {
    const newOrderMode = determineFulfillment(
      deliveryAddress,
      // @ts-expect-error: locationDetail cannot be undefined
      locationDetail,
      basketLocation,
      basketFulfillment,
    );

    if (newOrderMode !== orderMode) {
      setOrderMode(newOrderMode);
    }

    const locationHours = getOperatingHoursForLocation(locationDetail);
    const hours = getNextHourSlots(locationHours);

    const openHoursDateFormat = DATE_FORMAT.HOURS_WITH_MINUTES;
    const offset = locationDetail?.cached_data
      ? getSanitizedOffset(locationDetail.cached_data.utc_offset)
      : null;

    const openHours = deriveFormattedDateTimeString(
      hours[0]?.start,
      openHoursDateFormat,
      /** @TODO differentiate between `null` and `undefined`. */
      // @ts-expect-error
      offset,
    );
    setNextOpenHours(openHours);
    setBannerVisibility(true);
  };

  useEffect(() => {
    if (
      display === STORE_BANNER_DISPLAY.ALWAYS ||
      (display === STORE_BANNER_DISPLAY.STORE_CLOSED &&
        !isStoreCurrentlyOpen(getOperatingHoursForLocation(locationDetail)))
    ) {
      setBannerContent();
    }
  });

  // Additionally checking nextOpenHours to keep from rendering $openHours$ while StringAccessor is executing
  if (bannerVisibility && nextOpenHours) {
    return (
      <StyledStoreBanner>
        <StyledStoreBannerContent>
          <StringAccessor
            html={true}
            accessor="store.banner"
            dataObj={{
              openHours: nextOpenHours,
              conveyanceType: orderMode,
            }}
            tag="p"
          />
        </StyledStoreBannerContent>
      </StyledStoreBanner>
    );
  }

  return null;
};
