import { useEffect, useState } from 'react';
import styled from 'styled-components';
import StringAccessor from '@/components/cmsConfig/stringAccessor';
import { useConfigOverride } from '@/redux/cmsConfig';

export default function BackToTopCTA() {
  const [visible, setVisible] = useState(false);
  const { primary_active_color } = useConfigOverride('global');

  useEffect(() => {
    window.addEventListener('scroll', displayCTA);

    return () => {
      window.removeEventListener('scroll', displayCTA);
    };
  }, []);

  function displayCTA() {
    if (window.scrollY > 385) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  return (
    <Button data-css-override="StyledBackToTopCTA" $isVisible={visible} onClick={scrollToTop}>
      <svg width="20" height="12" viewBox="0 0 20 12" role="img">
        <title>Chevron Icon</title>
        <path
          fill="none"
          fillRule="nonzero"
          stroke={primary_active_color}
          strokeLinecap="round"
          strokeWidth="1.5"
          d="M18.323 10.323l-8.215-8.215-8.216 8.215"
        />
      </svg>

      <StringAccessor tag={Label} accessor="store.back_to_top_cta" html={true} />
    </Button>
  );
}

const Button = styled.button<{ $isVisible?: boolean }>(({ theme, $isVisible = false }) => ({
  all: 'unset',
  alignItems: 'center',
  backgroundColor: theme.global.body_color,
  border: '1px solid',
  borderColor: theme.global.primary_border_color ?? 'transparent',
  boxShadow: '0px 0px 8px 0px rgb(15 15 15 / 0.19)',
  bottom: '1.25rem',
  color: theme.global.primary_active_color,
  display: 'flex',
  borderRadius: '50%',
  height: '3rem',
  justifyContent: 'center',
  position: 'fixed',
  outline: 'revert',
  right: '1.25rem',
  transform: `translateY(${$isVisible ? 0 : 150}px)`,
  transition: 'all 0.4s ease',
  width: '3rem',
  visibility: 'hidden',
  zIndex: 10,

  '@media (min-width: 768px)': {
    visibility: 'visible',
  },
}));

const Label = styled.span({
  display: 'block',
  height: 0,
  overflow: 'hidden',
  width: 0,
});
