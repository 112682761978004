import { type MenuProduct } from '@koala/sdk';
import { type ReactNode } from 'react';
import { Card } from './card';
import { styled } from '@/components/content/stitches';
import { Box, Stack } from '@/components/ui';
import { useSlider } from '@/components/ui/use-slider/use-slider';
import Icon from '@/components/uielements/icon';
import { Render } from '@/components/uielements/render';
import { getProductImageSrc } from '@/utils/imageHelper';

interface Props {
  children: ReactNode;
  items: MenuProduct[];
  count: number;
  onClick: (item: MenuProduct, index: number) => void;
}

export function Widget({ children, items, count, onClick }: Props) {
  /*
   * The slider hook can be configured with:
   * - an item count
   * - a per page value (useful for complex sliders with multiple items in one slide, defaults to 1)
   */
  const { backProps, nextProps, itemProps, dots } = useSlider({
    count,
    perPage: 5,
  });

  if (!items.length) {
    return null;
  }

  return (
    <Container data-testid="popular-items-widget">
      {children}

      <Render condition={count > 5}>
        <Box
          data-testid="popular-items-widget-controls"
          css={{
            display: 'none',

            '@lg': {
              display: 'block',
            },
          }}
        >
          <Stack align="center" position="trailing">
            <SliderNav
              {...backProps}
              css={{
                transform: 'rotate(180deg)',
              }}
            >
              <Icon.Caret title="previous" />
            </SliderNav>

            <SliderNav {...nextProps}>
              <Icon.Caret title="next" />
            </SliderNav>
          </Stack>
        </Box>
      </Render>

      {/*
       *
       * the margin offset values work in the following way:
       * - the mobile and `@md` values are to offset the container padding
       * - the `@lg` value is to offset the per `<CardWrapper>` left and right padding
       * - this is because the container uses `overflow: scroll` until the @lg breakpoint
       */}
      <Box
        css={{
          margin: '0 -$3',
          '@md': { margin: '0 -$8' },
          '@lg': { margin: '0 -$3' },
        }}
      >
        <Stack
          align="fill"
          gap="0"
          css={{
            overflow: 'scroll',
            padding: '$7 0 0',
            scrollSnapType: 'x mandatory',
            scrollbarWidth: 'none',

            '-ms-overflow-style': 'none',

            '&::-webkit-scrollbar': {
              '-webkit-appearance': 'none',
              display: 'none',
              height: 0,
              width: 0,
            },

            '@lg': {
              justifyContent: count > 5 ? 'flex-start' : 'center',
              overflow: 'hidden',
            },
          }}
        >
          {items.map((item, index) => {
            return (
              <CardWrapper
                data-testid="popular-items-widget-card"
                key={item.id}
                css={{
                  transition: 'transform 0.6s ease',
                  '@lg': {
                    ...itemProps.style,
                  },
                }}
              >
                <Card
                  image={getProductImageSrc(item, { width: 300 })}
                  name={item.name}
                  description={item.description}
                  calories={item.pretty_calories}
                  price={item.pretty_price}
                  onClick={() => onClick(item, index)}
                />
              </CardWrapper>
            );
          })}

          <CardWrapper
            css={{
              width: '$1',

              '@lg': {
                display: 'none',
              },
            }}
          ></CardWrapper>
        </Stack>
      </Box>

      <Render condition={count > 5}>
        <Box
          css={{
            display: 'none',

            '@lg': {
              display: 'block',
            },
          }}
        >
          <Stack
            position="center"
            gap="$6"
            css={{
              padding: '$8 0 0',

              '@lg': {
                padding: '$12 0 0',
              },
            }}
          >
            {dots.map((dot, index) => (
              <SliderDot key={index} {...dot.props}>
                {dot.label}
              </SliderDot>
            ))}
          </Stack>
        </Box>
      </Render>
    </Container>
  );
}

const Container = styled('div', {
  textAlign: 'center',
});

const CardWrapper = styled('div', {
  flex: 'none',
  padding: '0 0 0 $2',
  scrollSnapAlign: 'start',
  width: '40%',

  '@sm': {
    padding: '0 0 0 $4',
    width: '30%',
  },

  '@lg': {
    padding: '0 $3',
    width: '20%',
  },
});

const SliderNav = styled('button', {
  all: 'unset',
  borderRadius: '$sm',
  cursor: 'pointer',

  svg: {
    display: 'block',
  },

  '&:focus': {
    backgroundColor: 'rgba(0,0,0,.08)',
  },

  variants: {
    disabled: {
      true: {
        cursor: 'default',
        opacity: 0.2,
      },
    },
  },
});

const SliderDot = styled('button', {
  all: 'unset',
  backgroundColor: '$accentPrimary',
  borderRadius: '100%',
  color: '$accentPrimary',
  height: '$3',
  opacity: 0.1,
  overflow: 'hidden',
  transition: 'all .2s ease',
  width: '$3',

  variants: {
    active: {
      true: {
        opacity: 1,

        '&:hover, &:focus': {
          opacity: 1,
        },
      },
    },
  },

  '&:hover': {
    opacity: 0.5,
  },

  '&:focus': {
    boxShadow: '0 0 0 2px white, 0 0 0 4px currentColor',
    opacity: 0.5,
  },

  '@lg': {
    cursor: 'pointer',
  },
});
