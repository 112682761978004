import Link from 'next/link';
import { StyledReorderViewAll, StyledReorderViewAllContainer } from './styles';
import StringAccessor from '@/components/cmsConfig/stringAccessor';
import { LOYALTY_ROUTES } from '@/constants/routes';

interface Props {
  expand?: boolean;
}

export const ViewAllReordersLink = (props: Props) => {
  const { expand } = props;

  return (
    <StyledReorderViewAllContainer expand={expand}>
      <Link href={LOYALTY_ROUTES.ORDER_HISTORY} passHref={true} legacyBehavior>
        <StyledReorderViewAll>
          <StringAccessor accessor="reorders.view_all_past_orders_cta" />
        </StyledReorderViewAll>
      </Link>
    </StyledReorderViewAllContainer>
  );
};
