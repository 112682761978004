import { readableColor, transparentize } from 'polished';
import { css } from 'styled-components';
import { safePolished } from '@/utils/safePolished';

/**
 * @param   {string|object} arg
 * @param   {object}        [breakpoints]
 * @returns {*}
 */
export const overflowShadow = (background: string) => {
  const shadow = safePolished(transparentize)(0.97, safePolished(readableColor)(background));

  return () => css`
    // Overflow shadow effect
    -ms-overflow-style: -ms-autohiding-scrollbar;
    background-image:
      /* Shadows */
      linear-gradient(to right, ${background}, ${background}),
      linear-gradient(to right, ${background}, ${background}),
      /* Shadow covers */ linear-gradient(to right, ${shadow}, ${background}),
      linear-gradient(to left, ${shadow}, ${background});
    background-position:
      left center,
      right center,
      left center,
      right center;
    background-repeat: no-repeat;
    background-color: ${background};
    background-size:
      20px 100%,
      20px 100%,
      20px 100%,
      20px 100%;

    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
  `;
};
