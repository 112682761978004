import { type Category, type Location } from '@koala/sdk';
import { List, StyledMenuNavLink } from './components/list';
import { styled } from '@/components/content/stitches';
import { Render } from '@/components/uielements/render';
import { CSS_CLASSES } from '@/constants/cssClassNames';
import { CATEGORY_NAV_DISPLAY } from '@/constants/menu';
import { useConfigOverride } from '@/redux/cmsConfig';

interface Props {
  categories: Category[];
  locationDetail: Location;
  activeTagIds: number[] | null;
}

export function Desktop({ categories, locationDetail, activeTagIds }: Props) {
  const { category_nav_background_color, category_nav_display, category_nav_text_color } =
    useConfigOverride('menu_categories');

  const { regular } = useConfigOverride('secondary_font');

  return (
    <Container
      data-testid="menu-navigation"
      css={{
        backgroundColor: category_nav_background_color,
      }}
      layout={category_nav_display}
      data-css-override="StyledDesktopNavLayout"
    >
      <Content layout={category_nav_display}>
        <Render condition={category_nav_display === CATEGORY_NAV_DISPLAY.COLUMN}>
          <Title
            data-css-override="StyledMenuTitle"
            css={{
              '& h1': {
                color: category_nav_text_color,
                fontFamily: regular.font_family,
              },
            }}
          >
            <h1>Menu</h1>
          </Title>
        </Render>

        <Navigation layout={category_nav_display} className={CSS_CLASSES.STORE_MENU.DESKTOP_NAV}>
          <List
            activeTagIds={activeTagIds}
            locationDetail={locationDetail}
            categories={categories}
            context="desktop"
          />
        </Navigation>
      </Content>
    </Container>
  );
}

const Container = styled('div', {
  display: 'none',
  zIndex: 9,

  variants: {
    layout: {
      sticky: {
        position: 'sticky',
        top: '8.5rem',

        '@xl': {
          top: '$20',
        },
      },
      inline: {},
      column: {},
      left: {},
    },
  },

  '@md': {
    display: 'block',
  },
});

const Content = styled('div', {
  display: 'flex',
  margin: '0 auto',
  maxWidth: '1440px',
  height: '$20',
  padding: '0 $5',
  width: '100%',

  variants: {
    layout: {
      sticky: {},
      inline: {},
      column: {
        height: 'auto',
        padding: '$5',

        '& > div': {
          flexBasis: '20%',
          flexGrow: 'initial',
        },
      },
      left: {
        padding: 0,
      },
    },
  },
});

const Title = styled('div', {
  '& h1': {
    fontSize: '2rem',
    margin: '$5 0',
  },
});

const Navigation = styled('nav', {
  display: 'flex',
  flexBasis: '100%',
  flexDirection: 'row',
  flexWrap: 'inherit',
  justifyContent: 'space-between',

  [`& ${StyledMenuNavLink}`]: {
    alignItems: 'center',
    display: 'flex',
    padding: '0 $3',
  },

  variants: {
    layout: {
      sticky: {},
      inline: {},
      column: {
        flexBasis: '60%',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',

        // @TODO: verify these settings and migrate them to the button itself
        [`& ${StyledMenuNavLink}`]: {
          width: '33.33%',

          [`&:hover, &.${CSS_CLASSES.STORE_MENU.ACTIVE_NAV_BUTTON}`]: {
            borderColor: 'transparent',
            textDecoration: 'underline',
          },
        },
      },

      left: {
        flexDirection: 'column',
        padding: '$5',
        height: 'calc(100vh - 100px)',
        justifyContent: 'flex-start',
        overflowY: 'scroll',

        [`& ${StyledMenuNavLink}`]: {
          display: 'block',
          padding: '$5',
        },
      },
    },
  },
});
