import { type MenuProduct, type Location } from '@koala/sdk/v4';
import { useState } from 'react';
import styled from 'styled-components';
import { Allergens } from '@/components/allergens/menuCard';
import { Image } from '@/components/image';
import { Stack } from '@/components/ui';
import { Card } from '@/components/ui/card';
import { Observer } from '@/components/ui/observer/observer';
import { Render } from '@/components/uielements/render';
import { ALLERGEN_CARD_MODE } from '@/constants/global';
import { Tag } from '@/features/menu/tag';
import { useDispatch, useSelector } from '@/redux';
import { useConfigOverride } from '@/redux/cmsConfig';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import commerceActions from '@/redux/commerce/actions';
import { getProductImageSrc } from '@/utils/imageHelper';
import { formatDisplayPriceByCountry, getAllProductOptionAllergens } from '@/utils/menu';

interface Props {
  onClick: () => void;
  product: MenuProduct;
  index: number;
  categoryName: string;
  location?: Location;
  showImage?: boolean;
}

const itemIsActive = (disabled_until: Date | null) => {
  const activeDate = disabled_until ? new Date(disabled_until) : null;

  if (!activeDate || activeDate < new Date()) {
    return true;
  }
  return false;
};

export function MenuCard({
  location,
  product,
  onClick,
  showImage = true,
  index,
  categoryName,
}: Props) {
  const [impressionAdded, setImpressionAdded] = useState(false);
  const { logo } = useConfigOverride('header');
  const { dietary_preferences_enabled } = useConfigOverride('menu');
  const { hide_calories, hide_price, card_orientation, hide_description, hide_description_mobile } =
    useConfigOverride('menu_product_card');
  const { label } = useSelector((state) => state.app.organization.organization);
  const image = getProductImageSrc(product, {
    height: 500,
  });
  const formattedPrice = formatDisplayPriceByCountry(
    product.pretty_price,
    location?.country ?? 'US',
  );

  const { disabled_until } = product;
  const isProductDisabled = !itemIsActive(disabled_until);

  const dispatch = useDispatch();

  const details = (
    <>
      <Render condition={showImage && Boolean(image)}>
        <Card.Image>
          <Image src={image} alt="Product image" layout="fill" />

          {/* Optional marketing tag */}
          <Tag value={product.marketing_tags?.[0] ?? null} />
        </Card.Image>
      </Render>

      <Render condition={showImage && card_orientation !== 'horizontal' && !Boolean(image)}>
        <Card.Image>
          <PlaceholderImage>
            {logo ? (
              <Image src={logo} alt={label} height={32} width={160} objectFit="contain" />
            ) : (
              <Text>{label}</Text>
            )}
          </PlaceholderImage>
        </Card.Image>
      </Render>

      <Card.Details>
        <Card.Title>{product.name}</Card.Title>

        <Render condition={Boolean(product.description) && !hide_description}>
          <MobileRender $shouldHide={hide_description_mobile}>
            <Card.Description>
              {product.description
                ?.split('\n')
                .map((item: MenuProduct['description'], index: number) => (
                  <p key={`line-${index}`}>{item.trim()}</p>
                ))}
            </Card.Description>
          </MobileRender>
        </Render>

        <Render
          condition={
            (!hide_price && Boolean(formattedPrice)) ||
            (!hide_calories && Boolean(product.pretty_calories))
          }
        >
          <Card.Footer>
            <Stack gap="$3">
              {!hide_price && formattedPrice && !isProductDisabled && (
                <Price>{formattedPrice}</Price>
              )}
              {!hide_calories && <Attribute>{product.pretty_calories}</Attribute>}
            </Stack>

            <Render condition={dietary_preferences_enabled}>
              <Allergens
                productAllergens={product}
                compileAllergensMethod={getAllProductOptionAllergens}
                mode={ALLERGEN_CARD_MODE.MENU}
              />
            </Render>
          </Card.Footer>
        </Render>
      </Card.Details>
    </>
  );

  return (
    <Button
      onClick={isProductDisabled ? undefined : onClick}
      $animateOnPointer={showImage && Boolean(image)}
      aria-haspopup
      aria-expanded={false}
      data-testid="menu-product-card"
      disabled={isProductDisabled}
    >
      <Observer
        threshold={0.01}
        onElementView={() => {
          if (!impressionAdded) {
            dispatch(commerceActions.commerceItemImpression(product, categoryName, index));
            setImpressionAdded(true);
          }
        }}
      ></Observer>
      <div style={{ opacity: isProductDisabled ? 0.3 : 1, height: '100%' }}>
        {card_orientation === 'vertical' ? (
          <Card.Vertical>{details}</Card.Vertical>
        ) : (
          <Card.Horizontal $hasImage={showImage && Boolean(image)}>{details}</Card.Horizontal>
        )}
      </div>
      {isProductDisabled && <OutOfStockBanner>Out of Stock</OutOfStockBanner>}
    </Button>
  );
}

const Button = styled.button<{ $animateOnPointer: boolean; disabled?: boolean }>(
  ({ theme, $animateOnPointer = true, disabled }) => ({
    all: 'unset',
    cursor: disabled ? 'default' : 'pointer',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    outline: 'revert',

    backgroundColor: theme.menu_product_card.background_color ?? 'transparent',
    border: `1px solid ${theme.menu_product_card.border_color ?? 'transparent'}`,
    borderRadius: `${theme.menu_product_card.border_radius ?? 0}px`,
    ...getFontStyles(theme.menu_product_card.font, {
      line_height: (value) => {
        if (value === 'revert') {
          return '145%';
        }
        return value;
      },
    }),

    [`& ${Card.Vertical}, & ${Card.Horizontal}`]: {
      height: '100%',
      transition: 'all .4s ease',
    },

    [`& ${Card.Image} img`]: {
      transition: 'all .4s ease',
    },

    [`&:hover ${Card.Image} img`]: {
      transform: $animateOnPointer ? 'scale(1.1)' : 'none',
    },

    [`&:hover ${Card.Vertical}, &:hover ${Card.Horizontal}`]: {
      borderColor: theme.global.primary_active_color,
    },
  }),
);

const Attribute = styled.span(({ theme }) => ({
  ...getFontStyles(theme.menu_product_card.font, {
    font_size: (value) => {
      if (typeof value === 'number') {
        return value * 0.875;
      }
      return value;
    },
  }),
}));

const Price = styled.span(({ theme }) => ({
  fontWeight: 700,
  ...getFontStyles(
    theme.menu_product_card.font,
    ['color', 'font_family', 'font_size', 'font_style', 'line_height'],
    {
      font_size: (value) => {
        if (typeof value === 'number') {
          return value * 0.875;
        }
        return value;
      },
    },
  ),
}));

const PlaceholderImage = styled.div({
  alignItems: 'center',
  backgroundColor: '#e4e4e4',
  display: 'flex',
  justifyContent: 'center',
  height: '100%',
  padding: '1rem',
  width: '100%',

  img: {
    filter: 'grayscale(1)',
    height: '2rem',
    opacity: 0.2,
    width: '10rem',
  },
});

const Text = styled.span(({ theme }) => ({
  fontWeight: 'bold',
  opacity: 0.2,
  ...getFontStyles(theme.header.font, ['font_family', 'font_size']),
}));

const MobileRender = styled.div<{ $shouldHide: boolean }>(({ $shouldHide }) => ({
  display: $shouldHide ? 'none' : 'block',

  '@media (min-width: 640px)': {
    display: 'block',
  },
}));

const OutOfStockBanner = styled.div({
  position: 'absolute',
  height: 40,
  left: '0',
  right: '0',
  bottom: '0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 0, 0, 0.7)',
  color: '#fff',
  fontSize: 14,
  zIndex: 1,
  pointerEvents: 'none', // Allows clicks to pass through the banner when disabled
});
