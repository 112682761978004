import styled from 'styled-components';

interface Props {
  value: {
    background_color?: string;
    hide_label?: boolean;
    label: string;
    text_color?: string;
    icon_image_url?: string | null;
  } | null;
}

export function Tag({ value }: Props) {
  if (!value) {
    return null;
  }

  return (
    <Container
      backgroundColor={value.background_color ?? 'inherit'}
      textColor={value.text_color ?? 'inherit'}
    >
      {value.icon_image_url && <Icon src={value.icon_image_url} alt={`${value.label} Tag Icon`} />}
      {!value.hide_label ? value.label : ''}
    </Container>
  );
}

interface StyleProps {
  backgroundColor: string;
  textColor: string;
}

const Container = styled.span<StyleProps>(({ backgroundColor, textColor }) => ({
  alignItems: 'center',
  background: backgroundColor,
  borderRadius: '0px var(--size-1) var(--size-1) 0px',
  color: textColor,
  display: 'flex',
  fontSize: '.625rem',
  justifyContent: 'center',
  left: 0,
  padding: 'var(--size-1) var(--size-2)',
  position: 'absolute',
  top: 'var(--size-2)',
}));

const Icon = styled.img({
  maxHeight: '1.25rem',
  marginRight: 'var(--size-1)',
});
