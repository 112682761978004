import styled from 'styled-components';
import { getFontStyles } from '@/redux/cmsConfig/utils';

const Details = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const Description = styled.div(({ theme }) => ({
  ...getFontStyles(theme.menu_product_card.font),
  margin: '.25rem 0 0',
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': '3',
  overflow: 'hidden',

  '@media (min-width: 768px)': {
    display: 'block',
  },

  p: {
    margin: 0,
  },
}));

const Footer = styled.footer({
  margin: 'auto 0 0',
  padding: '1.5rem 0 0',
});

const Image = styled.div(({ theme }) => ({
  aspectRatio: '1 / 1',
  borderRadius: theme.global.border_radius * 0.5 ?? 0,
  maxWidth: '100%',
  overflow: 'hidden',
  position: 'relative',

  img: {
    objectFit: 'cover',
    display: 'block',
  },
}));

const Title = styled.h2(({ theme }) => ({
  ...getFontStyles(theme.menu_product_card.secondary_font, {
    line_height: (value) => {
      if (value === 'revert') {
        return '145%';
      }

      return value;
    },
  }),
  margin: 0,
}));

const Horizontal = styled.article<{ $hasImage: boolean }>(({ theme, $hasImage = false }) => ({
  alignItems: 'center',
  border: '1px solid',
  borderColor: theme.menu_product_card.border_color ?? 'transparent',
  borderRadius: theme.menu_product_card.border_radius ?? 0,
  display: 'grid',
  gridTemplateColumns: $hasImage ? '1fr 5.625rem' : '1fr',
  gap: '1rem',
  padding: '.875rem 1rem',
  ...getFontStyles(theme.menu_product_card.font, {
    line_height: (value) => {
      if (value === 'revert') {
        return '145%';
      }

      return value;
    },
  }),

  '@media (min-width: 1024px)': {
    gridTemplateColumns: $hasImage ? '1fr 9.25rem' : '1fr',
  },

  [`& ${Image}`]: {
    gridColumn: '2',
  },

  [`& ${Details}`]: {
    gridColumn: '1',
    gridRow: '1',
  },
}));

const Vertical = styled.article(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.menu_product_card.border_color ?? 'transparent',
  borderRadius: theme.menu_product_card.border_radius ?? 0,
  display: 'flex',
  flexDirection: 'column',
  padding: '.75rem',
  ...getFontStyles(theme.menu_product_card.font, {
    line_height: (value) => {
      if (value === 'revert') {
        return '145%';
      }

      return value;
    },
  }),

  '@media (min-width: 768px)': {
    padding: '.875rem 1rem',
  },

  [`& ${Image} + ${Details}`]: {
    marginTop: '1rem',
  },
}));

export const Card = {
  Details,
  Description,
  Footer,
  Horizontal,
  Image,
  Title,
  Vertical,
};
