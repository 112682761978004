import { sdkUtils, type Category, type MenuProduct } from '@koala/sdk';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Observer } from '@/components/ui/observer/observer';
import { CATEGORY_MENU_HEADER_DISPLAY, CATEGORY_NAV_DISPLAY } from '@/constants/menu';
import { MenuCard } from '@/features/menu/card';
import { useDispatch, useSelector } from '@/redux';
import { useConfigOverride } from '@/redux/cmsConfig';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import commerceActions from '@/redux/commerce/actions';
import menuActions from '@/redux/menu/actions';
import { scrollToOffset } from '@/utils/global';
import { filterCategoriesByTagId, filterProductsByTagId } from '@/utils/menu';
import { useThrottledListener } from '@/utils/useThrottledListener';

interface Props {
  categories: Category[];
  customizeProduct: (item: MenuProduct, category: Category) => void;
  activeTagIds: number[] | null;
  currentCategory?: string;
}

interface ContainerRef {
  element: HTMLDivElement;
  id: string;
}

export const MenuCategories = ({
  categories,
  customizeProduct,
  currentCategory,
  activeTagIds,
}: Props) => {
  // we need to check multiple elements, so we have an array'd ref
  // instead of a one-off ref element.
  const containers = useRef<ContainerRef[]>([]);

  const { detail } = useSelector((state) => state.app.locations);
  const { requestedNavigationUpdate } = useSelector((state) => state.app.menu);
  const { category_nav_display } = useConfigOverride('menu_categories');
  const { mobile_web_product_grid } = useConfigOverride('menu');
  const { card_orientation, hide_image } = useConfigOverride('menu_product_card');
  const dispatch = useDispatch();

  const additionalOffset =
    category_nav_display === CATEGORY_NAV_DISPLAY.STICKY ||
    category_nav_display === CATEGORY_NAV_DISPLAY.LEFT
      ? 192
      : 0;

  useEffect(function () {
    if (!containers.current) {
      return;
    }

    const category = containers.current.find((container) => container.id === currentCategory);

    if (category) {
      window.scrollTo({
        top: scrollToOffset(category.element.getBoundingClientRect().top, additionalOffset),
      });
    } else {
      // immediately highlight the first category in the navigation bar
      updateActiveCategory();
    }
  }, []);

  useEffect(() => {
    if (!containers.current) {
      return;
    }

    const category = containers.current.find(
      (container) => container.id === requestedNavigationUpdate,
    );

    if (category) {
      window.scrollTo({
        top: scrollToOffset(category.element.getBoundingClientRect().top, additionalOffset),
        behavior: 'smooth',
      });
    }

    dispatch(menuActions.requestNavigationUpdate(''));
  }, [additionalOffset, dispatch, requestedNavigationUpdate]);

  function updateActiveCategory() {
    if (!containers.current) {
      return;
    }

    let id = '';

    containers.current.forEach((container) => {
      const bounds = container.element.getBoundingClientRect();

      // since we go top down, we just need to grab the last category that matches our filter
      // we have to use a slightly higher threshold here to avoid direct category clicks highlighting
      // the previous category
      if (bounds.top <= 250) {
        id = container.id;
      }
    });

    dispatch(menuActions.pushNavigationStatus(id));
  }

  // a memoized, throttled scroll listener we can pass to the window
  // this will ensure that we aren't peforming complex updates on every scroll event
  const { listener } = useThrottledListener(updateActiveCategory, 200);
  useEffect(() => {
    window.addEventListener('scroll', listener);

    return () => window.removeEventListener('scroll', listener);
  });

  return (
    <>
      {categories
        .filter((category: Category) => category.products.length > 0)
        .filter((category: Category) => filterCategoriesByTagId(category, activeTagIds))
        .map((category) => {
          const parsedCategoryDescription = category.description?.split('\n');
          let hasImage = false;

          if (!hide_image) {
            hasImage = category.products.some((product: MenuProduct) =>
              product.image || product.images?.image_url_1_by_1 ? true : false,
            );
          }

          return (
            <div
              key={category.id}
              ref={(el) => {
                // we can control how our ref is inserted
                // which is necessary if we want to use an array'd ref
                if (el) {
                  const id = String(category.id);
                  containers.current = containers.current
                    // we can have a situation where duplicate categories exist after
                    // navigating between food halls. A simple fix is to filter out the existing item
                    // this makes sense because we want the new, correct element existing on screen
                    .filter((item) => item.id !== id)
                    .concat({
                      element: el,
                      id,
                    });
                }
              }}
            >
              <Container data-menu-category-id={category.global_id}>
                <CategoryHeader className="koala__ui-menu-category-heading">
                  <Observer
                    key={category.id}
                    triggerOnce={true}
                    onElementView={() =>
                      dispatch(commerceActions.commerceCategoryView(category, detail))
                    }
                  >
                    <h2 id={sdkUtils.slugName(category)}>{category.name}</h2>
                    {parsedCategoryDescription?.map((line: string, index: number) => (
                      <p key={`line-${index}`}>{line.trim()}</p>
                    ))}
                  </Observer>
                </CategoryHeader>

                {/* Vertical cards have more columns */}
                <Grid
                  $cardOrientation={card_orientation}
                  $mobileGridCount={mobile_web_product_grid}
                >
                  {category.products
                    .filter((product: MenuProduct) =>
                      filterProductsByTagId(product.filter_tags, activeTagIds),
                    )
                    .map((product: MenuProduct, index: number) => {
                      return (
                        <MenuCard
                          key={product.id}
                          onClick={() => {
                            dispatch(
                              commerceActions.commerceItemClick(product, category.name, index),
                            );
                            customizeProduct(product, category);
                          }}
                          categoryName={category.name}
                          location={detail}
                          product={product}
                          showImage={hasImage}
                          index={index}
                        />
                      );
                    })}
                </Grid>
              </Container>
            </div>
          );
        })}
    </>
  );
};

export const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexWrap:
    theme.menu_categories.category_menu_header_display === CATEGORY_MENU_HEADER_DISPLAY.STACKED ||
    theme.menu_categories.category_menu_header_display === CATEGORY_NAV_DISPLAY.LEFT
      ? 'wrap'
      : 'nowrap',

  '@media screen and (max-width: 1024px)': {
    display: 'block',
  },
}));

const CategoryHeader = styled.div(({ theme }) => ({
  display: 'block',
  marginBottom: '20px',
  ...(theme.menu_categories.category_menu_header_display === 'stacked' && {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '655px',
    textAlign: 'center',
    width: '100%',
  }),
  ...(theme.menu_categories.category_menu_header_display === 'column' && {
    width: '15%',
  }),
  ...(theme.menu_categories.category_nav_display === 'left' &&
    theme.menu_categories.category_menu_header_display !== 'stacked' && {
      width: '100%',
      marginLeft: '20px',
    }),
  h2: {
    margin: 0,
    wordWrap: 'break-word',
    ...getFontStyles(theme.menu_category_header.font, ['color', 'font_family', 'font_size']),
  },
  p: {
    margin: '10px 0 0',
    ...getFontStyles(theme.menu_category_header.secondary_font, [
      'color',
      'font_family',
      'font_size',
    ]),
  },
  '@media (max-width: 1024px)': {
    margin: '0 auto var(--size-5)',
    width: '100%',
    'h2, p': {
      textAlign: 'center',
    },
  },
}));

interface GridProps {
  $cardOrientation: 'vertical' | 'horizontal';
  $mobileGridCount: '1x' | '2x';
}

const Grid = styled.div<GridProps>(({ $cardOrientation, $mobileGridCount }) => ({
  display: 'grid',
  gridTemplateColumns:
    $cardOrientation === 'vertical' && $mobileGridCount === '2x' ? '1fr 1fr' : '1fr',
  gap: '1rem',
  width: '100%',

  '@media screen and (min-width: 768px)': {
    gap: '1.5rem',
    gridTemplateColumns:
      $cardOrientation === 'vertical'
        ? 'repeat(auto-fill, minmax(12rem, 1fr))'
        : 'repeat(auto-fill, minmax(18rem, 1fr))',
  },

  '@media screen and (min-width: 1024px)': {
    gap: '1.5rem',
    gridTemplateColumns:
      $cardOrientation === 'vertical'
        ? 'repeat(auto-fill, minmax(14rem, 1fr))'
        : 'repeat(auto-fill, minmax(24rem, 1fr))',
  },
}));
