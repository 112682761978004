//@ts-nocheck -> many SDK types are out of date here
import { CONVEYANCE_TYPES, type Location } from '@koala/sdk/v4';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getFulfillmentTime } from '../menu-availability/conveyance';
import { useHandoffTimes } from '../handoff/time-picker';
import GenericModal from '@/components/uielements/genericModal';
import {
  StyledGenericModalContent,
  StyledGenericModalFooter,
} from '@/components/uielements/genericModal/styles';
import { areAnyOperatingHoursAfterNow, isStoreCurrentlyOpen } from '@/utils/locations';
import { MODAL } from '@/constants/events';
import { type IBasketState } from '@/types/basket';
import { type IConveyanceModeState } from '@/types/conveyanceMode';
import { type ILocationsState } from '@/types/locations';
import { StyledPrimaryButton } from '@/components/uielements/primaryButton/styles';
import conveyanceModeActions from '@/redux/conveyanceMode/actions';
import { DATE_FORMAT } from '@/constants/dates';
import { formatDate, getSanitizedOffset } from '@/utils/dates';

interface Props {
  basket: IBasketState;
  address: IConveyanceModeState['address'];
  location?: ILocationsState | Location;
  timeWanted: string;
}

export const ConveyanceReconciliation = ({ basket, address, location, timeWanted }: Props) => {
  const isOpen = isStoreCurrentlyOpen(location?.operating_hours);
  const hasOpenHoursLeftInTheDay = areAnyOperatingHoursAfterNow(location?.operating_hours);
  const [modalShown, setModalShown] = useState<boolean>(!isOpen);
  const dispatch = useDispatch();

  const userGMTOffset = getSanitizedOffset(-(new Date().getTimezoneOffset() / 60));
  const tomorrow = new Date().setDate(new Date().getDate() + 1);
  const formattedDay = formatDate(tomorrow, DATE_FORMAT.YEAR_MONTH_DAY_DASHED);
  const startOfTomorrow = `${formattedDay}T00:00:00${userGMTOffset}`;

  // set initial time wanted
  const dayNeededForReq = !isOpen && !hasOpenHoursLeftInTheDay ? startOfTomorrow : ''; // '' defaults to current day. If closed, use start of next day (12am)
  const { data: handoffTimeData, isSuccess } = useHandoffTimes({
    dayWanted: dayNeededForReq,
    handoffType: CONVEYANCE_TYPES.PICKUP,
    locationId: location?.id,
    supportsAsap: false,
  });

  if (isOpen && !timeWanted && handoffTimeData?.length > 1 && isSuccess) {
    // if open
    dispatch(
      conveyanceModeActions.setWantedTime(
        handoffTimeData?.[0],
        location.id,
        CONVEYANCE_TYPES.PICKUP,
      ),
    );
  } else if (!isOpen && !timeWanted && handoffTimeData?.length > 1 && isSuccess) {
    // if closed but will repoen that same day (has handoffTimeData res)
    dispatch(
      conveyanceModeActions.setWantedTime(handoffTimeData[1], location.id, CONVEYANCE_TYPES.PICKUP),
    );
  } else if (
    !isOpen &&
    !timeWanted &&
    !hasOpenHoursLeftInTheDay &&
    handoffTimeData?.length > 1 &&
    isSuccess
  ) {
    // if closed for the day (no handoffTimeData res for remaining hours in the day)
    dispatch(
      conveyanceModeActions.setWantedTime(handoffTimeData[0], location.id, CONVEYANCE_TYPES.PICKUP),
    );
  }

  return (
    <div>
      {/* Alert if outside of business/operating hours */}
      <GenericModal modalOpen={modalShown} name={MODAL.OUTSIDE_OPERARING_HOURS} header={''}>
        <StyledGenericModalContent>
          <div style={{ padding: '40px 20px 20px 20px' }}>
            Your earliest available{' '}
            {
              //@ts-expect-error
              basket?.fulfillment?.type || address?.type || CONVEYANCE_TYPES.PICKUP
            }{' '}
            time is:
            <br></br>
            {getFulfillmentTime(timeWanted)}
          </div>
        </StyledGenericModalContent>
        <StyledGenericModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
          <StyledPrimaryButton
            data-css-override="StyledPrimaryButton"
            aria-label="Got it"
            onClick={() => setModalShown(false)}
          >
            Start Order
          </StyledPrimaryButton>
        </StyledGenericModalFooter>
      </GenericModal>
    </div>
  );
};
