import { readableColor } from 'polished';
import styled from 'styled-components';
import { StyledPrimaryButton } from '@/components/uielements/primaryButton/styles';
import { LAYOUT, ORDER, COLORS, SPACING_SCALE } from '@/constants/styles';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import { safePolished } from '@/utils/safePolished';

interface StyledMenuSearchContainerProps {
  $modalOpen: boolean;
}

export const StyledMenuSearchContainer = styled.div<StyledMenuSearchContainerProps>(
  ({ theme, $modalOpen }) => ({
    backgroundColor: $modalOpen ? theme.modals.background_color ?? 'transparent' : 'transparent',
    padding: $modalOpen ? '.75rem 1rem 1.5rem' : 0,
    position: $modalOpen ? 'fixed' : 'static',
    inset: 0,
    top: '6.5rem',
    zIndex: ORDER.MENU_SEARCH_MODAL,
  }),
);

export const StyledMenuSearchContainerHeader = styled.div({
  display: 'flex',
});

export const StyledCancelButton = styled(StyledPrimaryButton)`
  border-color: transparent;
  margin: 0 0 0 ${SPACING_SCALE.XS}px;
  min-width: 0;
  padding: 0 ${SPACING_SCALE.XS}px;
`;

export const StyledMenuSearchInputContainer = styled.div`
  flex-grow: 1;
  position: relative;

  input {
    height: ${LAYOUT.MENU_SEARCH_HEIGHT}px;
    padding: ${SPACING_SCALE.XS}px ${SPACING_SCALE.XL}px ${SPACING_SCALE.XS}px
      ${SPACING_SCALE.SMALL}px;
    -webkit-appearance: none;
    width: 100%;

    ${({ theme }) => `
      background-color: ${theme.forms.background_color};
      border: 1px solid ${theme.forms.border_color};
      border-radius: ${theme.forms.border_radius}px;
      ...getFontStyles(${theme.forms}, ['font_family', 'font_size', 'color']),

      &:focus {
        border: 1px solid ${theme.global.primary_active_color};
      }
    `}
  }
`;

export const StyledClearSearch = styled.button<{ $isVisible: string }>`
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  height: ${LAYOUT.MENU_SEARCH_HEIGHT}px;
  opacity: ${({ $isVisible }) => ($isVisible && 1) || 0};
  position: absolute;
  padding: 0 ${SPACING_SCALE.XS}px;
  right: 0;
  top: 0;
  width: 48px;

  img {
    display: block;
  }
`;

export const StyledMagnifyingGlass = styled.div`
  align-items: center;
  display: flex;
  height: ${LAYOUT.MENU_SEARCH_HEIGHT}px;
  padding: 0 ${SPACING_SCALE.SMALL}px;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
`;

const mobileSearchResultHeight =
  LAYOUT.MOBILE_HEADERHEIGHT +
  SPACING_SCALE.SMALL +
  SPACING_SCALE.MEDIUM +
  LAYOUT.MENU_SEARCH_HEIGHT +
  2;

export const StyledSearchResults = styled.ul`
  background-color: ${({ theme }) => theme.modals.background_color};
  border-radius: ${({ theme }) => theme.global.border_radius}px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  left: 0;
  margin: 0;
  max-height: 350px;
  overflow: scroll;
  padding: 0;
  position: absolute;
  width: 100%;

  @media (max-width: 767px) {
    box-shadow: none;
    margin: ${SPACING_SCALE.MEDIUM}px -${SPACING_SCALE.SMALL}px 0;
    height: calc(100vh - ${mobileSearchResultHeight}px);
    max-height: none;
    padding: 0 0;
    position: relative;
    width: calc(100% + ${SPACING_SCALE.LARGE}px);
  }

  li {
    border-bottom: 1px solid ${COLORS.LIGHTERGRAY};
    list-style-type: none;
    margin: 0 ${SPACING_SCALE.SMALL}px;
    padding: 0;

    &:last-of-type {
      border-bottom: none;
    }
  }
`;

export const StyledNoResults = styled.div`
  padding: ${LAYOUT.GUTTER}px ${LAYOUT.GUTTER * 2}px;

  h2 {
    font-size: inherit;
  }

  h2,
  p {
    text-align: center !important;
  }
`;

export const StyledSearchResult = styled.button(({ theme }) => ({
  alignItems: 'center',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  margin: `-1px -${SPACING_SCALE.SMALL}px`,
  minHeight: `${LAYOUT.MENU_SEARCH_RESULT_HEIGHT}px`,
  maxWidth: `calc(100% + ${SPACING_SCALE.LARGE}px)`,
  padding: `14px ${SPACING_SCALE.SMALL}px`,
  position: 'relative',
  textAlign: 'left',
  width: `calc(100% + ${SPACING_SCALE.LARGE}px)`,
  ...getFontStyles(theme.modals.font, ['color']),

  '&:hover': {
    background: theme.buttons.primary_button_background_color,
    ...getFontStyles(theme.buttons.primary_font, ['color'], {
      /** @FIXME font refactor. how will this work if primary_font.color is returned as inherit | initial | etc */
      color: (value) =>
        value ?? safePolished(readableColor)(theme.buttons.primary_button_background_color),
    }),
  },

  '@media (max-width: 767px)': {
    ...getFontStyles(theme.modals.font, ['color']),
  },
}));

interface StyledSearchResultImageProps {
  backgroundImage: string;
}

export const StyledSearchResultImage = styled.div<StyledSearchResultImageProps>`
  background-position: center;
  height: ${LAYOUT.GUTTER * 2}px;
  margin: 0 ${LAYOUT.GUTTER / 2}px 0 0;
  width: ${LAYOUT.GUTTER * 2}px;

  ${({ backgroundImage, theme }) => `
    background-image: url(${backgroundImage});
    background-size: ${theme.menu_product_card.image_size};
  `}
`;
