import { useEffect, type PropsWithChildren } from 'react';
import { useInView } from 'react-intersection-observer';

interface Props {
  onElementView: () => void;
  threshold?: number;
  triggerOnce?: boolean;
}

export function Observer({
  children,
  onElementView,
  threshold = 0.5,
  triggerOnce = false,
}: PropsWithChildren<Props>) {
  const { ref, inView } = useInView({ threshold, triggerOnce });

  useEffect(() => {
    if (inView) {
      onElementView();
    }
    // adding onElementView will create an infinite loop,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return <div ref={ref}>{children}</div>;
}
