import { type Category, type Location } from '@koala/sdk';
import { useEffect, useRef } from 'react';
import { DropDown } from './components/dropdown';
import { List, StyledMenuNavLink } from './components/list';
import { styled } from '@/components/content/stitches';
import { ChevronDown } from '@/components/uielements/chevron';
import { Render } from '@/components/uielements/render';
import { CSS_CLASSES } from '@/constants/cssClassNames';
import { MOBILE_CATEGORY_NAV_DISPLAY } from '@/constants/menu';
import { useSelector } from '@/redux';
import { useConfigOverride } from '@/redux/cmsConfig';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import { getElementOffset } from '@/utils/global';
import { deriveCategoryNavId } from '@/utils/menu';

interface Props {
  categories: Category[];
  locationDetail: Location;
  activeTagIds: number[] | null;
}

export function Mobile({ activeTagIds, categories, locationDetail }: Props) {
  const navigationStatus = useSelector((state) => state.app.menu.navigationStatus);
  const { mobile_category_nav_display, category_nav_background_color, category_nav_text_color } =
    useConfigOverride('menu_categories');
  /** @FIXME font refactor. */
  const { bold } = useConfigOverride('primary_font');
  const { background_color, border_color, border_radius, font } = useConfigOverride('forms');
  const listRef = useRef<HTMLDivElement>(null);
  const listContext = 'mobile';

  // Scroll active menu categories into view
  useEffect(() => {
    const activeElement = document.getElementById(
      deriveCategoryNavId(navigationStatus, listContext),
    );

    if (listRef.current) {
      listRef.current.scrollTo({
        left: getElementOffset(activeElement).left,
        behavior: 'smooth',
      });
    }
  }, [navigationStatus]);

  return (
    <Container
      data-testid="menu-navigation"
      css={{
        backgroundColor: category_nav_background_color,
      }}
      className={CSS_CLASSES.STORE_MENU.MOBILE_NAV}
    >
      <Render condition={mobile_category_nav_display === MOBILE_CATEGORY_NAV_DISPLAY.DROPDOWN}>
        <DropdownWrapper
          css={{
            '& select': {
              backgroundColor: background_color,
              border: `1px solid ${border_color}`,
              borderRadius: `${border_radius}px`,
              fontSize: '1rem',
              ...getFontStyles(font, ['color', 'font_family', 'font_weight', 'line_height']),
            },
          }}
        >
          <DropDown categories={categories} />

          <DropdownIcon>
            <ChevronDown />
          </DropdownIcon>
        </DropdownWrapper>
      </Render>

      <Render condition={mobile_category_nav_display === MOBILE_CATEGORY_NAV_DISPLAY.SCROLL}>
        <ListWrapper
          ref={listRef}
          css={{
            color: category_nav_text_color,
            fontFamily: bold.font_family,
          }}
        >
          <List
            activeTagIds={activeTagIds}
            categories={categories}
            locationDetail={locationDetail}
            context="mobile"
          />
        </ListWrapper>
      </Render>
    </Container>
  );
}

const Container = styled('div', {
  display: 'block',
  height: '$12',
  position: 'sticky',
  top: '6.5rem',
  zIndex: 1,

  '& > div ~ div': {
    paddingTop: 0,
  },

  '@md': {
    display: 'none',
  },
});

const DropdownWrapper = styled('div', {
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  padding: '$1-5',
  position: 'relative',

  '& select': {
    appearance: 'none',
    display: 'block',
    height: '100%',
    padding: '0 $12 0 $4',
    width: '100%',
  },
});

const ListWrapper = styled('div', {
  transition: 'all 0.2s linear',
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  overflowX: 'scroll',
  width: '100%',
  '-webkit-overflow-scrolling': 'touch',

  [`& ${StyledMenuNavLink}`]: {
    alignItems: 'center',
    display: 'flex',
    flexShrink: '0',
    height: '100%',
    padding: '0 $5',
  },
});

const DropdownIcon = styled('div', {
  alignItems: 'center',
  display: 'flex',
  padding: '0 $4',
  pointerEvents: 'none',
  position: 'absolute',
  right: '$2-5',
});
