import { withRouter, type NextRouter } from 'next/router';
import { compose } from 'redux';
import {
  CONVEYANCE_TYPES,
  type Category,
  type Location,
  type MenuProductWithCategory,
} from '@koala/sdk';
import get from 'lodash/get';
import Link from 'next/link';
import { connect, type ConnectedProps, useDispatch } from 'react-redux';
import { type FC, useEffect, useState } from 'react';
import { genericEventHandler } from '@/analytics/events';
import { EventNames, GlobalEvents } from '@/analytics/events/constants';
import StringAccessor from '@/components/cmsConfig/stringAccessor';
import GenericModal from '@/components/uielements/genericModal';
import {
  StyledAlertCta,
  StyledChangeLocation,
  StyledGenericModalContent,
  StyledGenericModalFooter,
  StyledSecondaryButton,
} from '@/components/uielements/genericModal/styles';
import { PRODUCT_LOCATION_LABELS } from '@/constants/checkout';
import { BASKET_ORDER_KEYS, K_ANALYTICS_EVENTS, MODAL } from '@/constants/events';
import { CART_MIGRATION_TYPES } from '@/constants/locations';
import { ROUTES } from '@/constants/routes';
import basketActions from '@/redux/basket/actions';
import conveyanceModeActions from '@/redux/conveyanceMode/actions';
import customizeActions from '@/redux/customize/actions';
import globalActions from '@/redux/global/actions';
import { type RootState } from '@/types/app';
import { migrateBasketItems } from '@/utils/basket';
import * as ErrorReporter from '@/utils/errorReporter';
import { fireGenericOrderEventsHandler } from '@/utils/events';
import { fireGaEvent, gaActions, gaCats } from '@/utils/googleAnalytics';
import { fireKAnalyticsEvent } from '@/utils/koalaAnalytics';

interface Props extends ReduxProps {
  menuCategories: Category[];
  menuLoading: boolean;
}

interface WithRouterProps extends Props {
  router: NextRouter;
  menuLoading: boolean;
}

const CartReconciliation: FC<WithRouterProps> = ({
  router,
  basketContent,
  basketLocation,
  cartMigration,
  locationDetail,
  setCartMigration,
  basketCreatedAt,
  basketFulfillment,
  deliveryAddress,
  basketMenuCategories,
  menuCategories,
  locationsLoading,
  fulfillmentModal,
  menuLoading,
  toggleFulfillmentModal,
  clearDeliveryAddress,
  checkDeliveryAddress,
  replaceBasket,
  setProduct,
}) => {
  const dispatch = useDispatch();
  const [modalShown, setModalShown] = useState<boolean>(false);
  const [productsNotMigrated, setProductsNotMigrated] = useState<MenuProductWithCategory[]>([]);
  const [errorModalShown, setErrorModalShown] = useState<boolean>(false);

  useEffect(() => {
    checkCartLocation();
  }, []);

  useEffect(() => {
    checkCartLocation();
  }, [locationsLoading]);

  const checkValidity = () => {
    const currentValidity = fulfillmentModal.addressValidity;
    switch (currentValidity) {
      case false:
        setErrorModalShown(true);
        return;
      case true:
        migrateBasket();
        setErrorModalShown(false);
        // KA Event
        fireKAnalyticsEvent(K_ANALYTICS_EVENTS.LOCATION_CHANGE, {
          name: MODAL.CART_MIGRATION_ACCEPTED,
          details: `location_name: ${locationDetail?.label}, location_id: ${locationDetail?.id}, fulfillment_change: ${CONVEYANCE_TYPES.DELIVERY}`,
        });
        return;
      default:
        setErrorModalShown(false);
        return;
    }
  };

  const migrateBasketToNewLocation = (
    newLocation: Location,
    newLocationMenuCategories: Category[],
  ) => {
    const basketMigration = migrateBasketItems(
      basketContent.basket_items,
      basketMenuCategories,
      newLocationMenuCategories,
    );

    replaceBasket(
      basketMigration.migratedBasketItems,
      newLocation,
      newLocationMenuCategories,
      basketCreatedAt,
      get(deliveryAddress ? deliveryAddress : {}, 'address'),
    );

    if (basketMigration.productsNotMatched.length) {
      setProductsNotMigrated(basketMigration.productsNotMatched);

      // KA Event
      fireGenericOrderEventsHandler(K_ANALYTICS_EVENTS.ITEMS_NOT_AVAILABLE, basketContent, null, [
        BASKET_ORDER_KEYS.BASKET_PRODUCTS,
      ]);
    }

    genericEventHandler(GlobalEvents.STORE__BASKET_RECONCILIATION, {
      name: deliveryAddress.type || CONVEYANCE_TYPES.PICKUP,
      details: basketMigration.productsNotMatched.length ? 'products_removed' : 'no_removals',
    });
  };

  const migrateBasket = () => {
    // Add a null check for locationDetail
    if (locationDetail) {
      migrateBasketToNewLocation(locationDetail, menuCategories);
      setCartMigration();

      const pendingItem = cartMigration.pendingItem;
      if (pendingItem) {
        setProduct({
          product: pendingItem,
          menuCategories,
          label: PRODUCT_LOCATION_LABELS.MENU,
        });
      }

      // GA Event
      fireGaEvent(gaCats.findShop, gaActions.changeLocation, {
        label: `${basketLocation.label} to ${locationDetail?.label}`,
      });
    } else {
      // Handle the case where locationDetail is undefined
      console.error('Location detail is undefined.');
    }
  };

  const resetBasket = () => {
    setCartMigration();
    const pendingItem = cartMigration.pendingItem;
    if (pendingItem) {
      setProduct({
        product: pendingItem,
        menuCategories,
        label: PRODUCT_LOCATION_LABELS.MENU,
      });
    }
  };

  const checkCartLocation = () => {
    // Pass if we're already displaying the modal or if the modal has already been shown
    if (cartMigration.type || modalShown) {
      setModalShown(true);
      return;
    }

    // Test conditions when to present cart to new location migration modal
    if (locationDetail?.id && basketLocation?.id && !locationsLoading && !menuLoading) {
      if (locationDetail.id !== basketLocation.id) {
        dispatch(setCartMigration(CART_MIGRATION_TYPES.MIGRATE));
      }
    }
  };

  const deliveryOnlyLocation = locationDetail?.is_delivery_only;

  return (
    <>
      {/* Cart Migration Modal */}
      <GenericModal
        modalOpen={!!cartMigration.type && !errorModalShown}
        toggleModal={() => setCartMigration()}
        name={MODAL.CART_MIGRATION_MODAL}
        disableModalClose={deliveryOnlyLocation}
        header={`Your current order is set for ${basketFulfillment?.type} from ${basketLocation.label}`}
      >
        <StyledGenericModalContent>
          <p style={{ padding: 20 }}>
            Please confirm you would like to update your order to our {locationDetail?.label} store.
          </p>
        </StyledGenericModalContent>

        <StyledGenericModalFooter>
          {/* If a user has previously entered a delivery address and clicks on new location */}
          {basketFulfillment && basketFulfillment.address ? (
            <>
              {!deliveryOnlyLocation && (
                <StyledAlertCta
                  size="large"
                  aria-label={`Pickup from ${locationDetail?.label}`}
                  onClick={() => {
                    migrateBasket();
                    if (locationDetail && locationDetail.id !== undefined) {
                      clearDeliveryAddress(locationDetail.id);
                    }
                    // KA Event
                    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.LOCATION_CHANGE, {
                      name: MODAL.CART_MIGRATION_ACCEPTED,
                      details: `location_name: ${locationDetail?.label}, location_id: ${locationDetail?.id}, fulfillment_change: ${CONVEYANCE_TYPES.PICKUP}`,
                    });
                  }}
                >
                  Pickup from {locationDetail?.label}
                </StyledAlertCta>
              )}

              {/* If new store supports delivery */}
              {locationDetail?.supports_delivery && (
                <StyledAlertCta
                  size="large"
                  aria-label={`Deliver from ${locationDetail?.label}`}
                  onClick={() => {
                    if (basketFulfillment.address) {
                      checkDeliveryAddress(basketFulfillment.address, locationDetail, true);
                    }
                    checkValidity();
                  }}
                >
                  Deliver from {locationDetail?.label}
                </StyledAlertCta>
              )}
            </>
          ) : (
            <StyledAlertCta
              size="large"
              aria-label={`Update order to ${locationDetail?.label}`}
              onClick={() => {
                migrateBasket();
                // KA Event
                fireKAnalyticsEvent(K_ANALYTICS_EVENTS.LOCATION_CHANGE, {
                  name: MODAL.CART_MIGRATION_ACCEPTED,
                  details: `location_name: ${locationDetail?.label}, location_id: ${locationDetail?.id}`,
                });
              }}
            >
              Update Order
            </StyledAlertCta>
          )}

          {/* Secondary CTA if we've landed on the store menu  */}
          {cartMigration.type === CART_MIGRATION_TYPES.MIGRATE &&
            (deliveryOnlyLocation ? (
              <StyledChangeLocation>
                <Link
                  href={ROUTES.LOCATIONS}
                  passHref={true}
                  onClick={() => {
                    toggleFulfillmentModal(true);
                    genericEventHandler(GlobalEvents.GENERIC__CTA, {
                      name: EventNames.CHANGE_LOCATION,
                      details: MODAL.CART_MIGRATION_MODAL,
                    });
                  }}
                >
                  <StringAccessor accessor="cart_checkout.change_location_cta" />
                </Link>
              </StyledChangeLocation>
            ) : (
              <StyledSecondaryButton
                data-css-override="StyledSecondaryButton"
                aria-label={`Don't update order to ${locationDetail?.label}`}
                onClick={() => {
                  setCartMigration();
                  // KA Event
                  fireKAnalyticsEvent(K_ANALYTICS_EVENTS.LOCATION_CHANGE, {
                    name: MODAL.CART_MIGRATION_DISMISSED,
                    details: `location_name: ${locationDetail?.label ?? ''}, location_id: ${
                      locationDetail?.id ?? ''
                    }`,
                  });
                  void router.push(`${ROUTES.HOMEPAGE}/store/${basketContent.location.id}`);
                }}
              >
                Don&apos;t Update Order
              </StyledSecondaryButton>
            ))}

          {/* Secondary CTA if we've clicked on a product  */}
          {cartMigration.type === CART_MIGRATION_TYPES.RESET &&
            (deliveryOnlyLocation ? (
              <StyledChangeLocation>
                <Link
                  href={ROUTES.LOCATIONS}
                  passHref={true}
                  onClick={() => {
                    toggleFulfillmentModal(false);
                    genericEventHandler(GlobalEvents.GENERIC__CTA, {
                      name: EventNames.CHANGE_LOCATION,
                      details: MODAL.CART_MIGRATION_MODAL,
                    });
                  }}
                >
                  <StringAccessor accessor="cart_checkout.change_location_cta" />
                </Link>
              </StyledChangeLocation>
            ) : (
              <>
                <StyledSecondaryButton
                  data-css-override="StyledSecondaryButton"
                  onClick={() => {
                    resetBasket();
                    // KA Event
                    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.LOCATION_CHANGE, {
                      name: MODAL.CART_MIGRATION_DISMISSED,
                      details: `location_name: ${locationDetail?.label}, location_id: ${locationDetail?.id}`,
                    });
                  }}
                >
                  Continue Without Updating
                </StyledSecondaryButton>
                <p style={{ fontStyle: 'italic' }}>
                  Adding an item without updating the location will clear the cart before adding new
                  items.
                </p>
              </>
            ))}
        </StyledGenericModalFooter>
      </GenericModal>

      {/* Alert if some products have not been successfully migrated */}
      <GenericModal
        modalOpen={!!productsNotMigrated.length}
        toggleModal={() => setProductsNotMigrated([])}
        name={MODAL.CART_MIGRATION_NOTICES_MODAL}
        header={'Please check your order'}
      >
        <StyledGenericModalContent>
          {productsNotMigrated.length > 0 && (
            <div style={{ padding: 20, textAlign: 'left' }}>
              <p>
                Sorry, but the {basketLocation.label} location does not have the following products
                and they have been removed from your order:
              </p>
              <ul>
                {productsNotMigrated.map((product: MenuProductWithCategory, index) => (
                  <li key={index}>{product.name}</li>
                ))}
              </ul>
            </div>
          )}
        </StyledGenericModalContent>
        <StyledGenericModalFooter>
          <StyledSecondaryButton
            data-css-override="StyledSecondaryButton"
            aria-label="Got it"
            onClick={() => setProductsNotMigrated([])}
          >
            Got it
          </StyledSecondaryButton>
        </StyledGenericModalFooter>
      </GenericModal>

      {/* Alert if new selected location does not delivery to address */}
      <GenericModal
        modalOpen={errorModalShown}
        toggleModal={() => setErrorModalShown(false)}
        name={MODAL.CANNOT_DELIVER_TO_ADDRESS_MODAL}
        header="Sorry! Issue with Delivery"
        disableModalClose={deliveryOnlyLocation}
      >
        <StyledGenericModalContent>
          <div style={{ padding: 20, textAlign: 'left' }}>
            <p>{fulfillmentModal.errorMessage}</p>
          </div>
        </StyledGenericModalContent>
        <StyledGenericModalFooter>
          {!deliveryOnlyLocation && (
            <StyledAlertCta
              size="large"
              aria-label={`Pickup from ${locationDetail?.label}`}
              onClick={() => {
                migrateBasket();
                setErrorModalShown(false);
                if (locationDetail && locationDetail.id !== undefined) {
                  clearDeliveryAddress(locationDetail.id);
                }
                // KA Event
                fireKAnalyticsEvent(K_ANALYTICS_EVENTS.LOCATION_CHANGE, {
                  name: MODAL.CART_MIGRATION_ACCEPTED,
                  details: `location_name: ${locationDetail?.label}, location_id: ${locationDetail?.id}, fulfillment_change: ${CONVEYANCE_TYPES.PICKUP}`,
                });
              }}
            >
              Switch to Pickup
            </StyledAlertCta>
          )}

          {/* If we could not validate the previous address with the new location - CTA to bring up the fulfillment modal */}
          <StyledAlertCta
            size="large"
            aria-label={`Retry Delivery from ${locationDetail?.label}`}
            onClick={() => {
              setErrorModalShown(false);
              setCartMigration();
              toggleFulfillmentModal(
                true,
                locationDetail,
                CONVEYANCE_TYPES.DELIVERY,
                false,
                undefined,
                true,
                deliveryOnlyLocation,
              );
              checkValidity();

              // Log in error reporter to see if this actually happens
              ErrorReporter.captureMessage(
                'Delivery address or details not valid at new store location. User to re-enter info',
                'info',
                {
                  deliveryAddress: basketFulfillment.address,
                  storeId: locationDetail?.id,
                  basketLocationId: basketLocation.id,
                },
              );
            }}
          >
            Re-Enter Delivery Details
          </StyledAlertCta>

          {deliveryOnlyLocation ? (
            <StyledChangeLocation>
              <Link
                href={ROUTES.LOCATIONS}
                passHref={true}
                onClick={() => {
                  toggleFulfillmentModal(false);
                  genericEventHandler(GlobalEvents.GENERIC__CTA, {
                    name: EventNames.CHANGE_LOCATION,
                    details: MODAL.CART_MIGRATION_NOTICES_MODAL,
                  });
                }}
              >
                <StringAccessor accessor="cart_checkout.change_location_cta" />
              </Link>
            </StyledChangeLocation>
          ) : (
            <StyledSecondaryButton
              data-css-override="StyledSecondaryButton"
              aria-label={`Don't update order to ${locationDetail?.label}`}
              onClick={() => {
                setErrorModalShown(false);
                setCartMigration();
                // KA Event
                fireKAnalyticsEvent(K_ANALYTICS_EVENTS.LOCATION_CHANGE, {
                  name: MODAL.CART_MIGRATION_DISMISSED,
                  details: `location_name: ${locationDetail?.label}, location_id: ${locationDetail?.id}`,
                });
              }}
            >
              Don&apos;t Update Order
            </StyledSecondaryButton>
          )}
        </StyledGenericModalFooter>
      </GenericModal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  basketContent: state.app.basket.content,
  basketLocation: state.app.basket.location,
  basketCreatedAt: state.app.basket.createdAt,
  basketFulfillment: state.app.basket.fulfillment,
  cartMigration: state.app.global.cartMigration,
  deliveryAddress: state.app.conveyanceMode,
  basketMenuCategories: state.app.menu.basketMenu,
  locationDetail: state.app.locations.detail,
  locationsLoading: state.app.locations.loading,
  fulfillmentModal: state.app.global.fulfillmentModal,
});

const mapDispatchToProps = {
  setCartMigration: globalActions.setCartMigration,
  setProduct: customizeActions.setProduct,
  replaceBasket: basketActions.replaceBasket,
  checkDeliveryAddress: conveyanceModeActions.checkDeliveryAddress,
  clearDeliveryAddress: conveyanceModeActions.clearDeliveryAddress,
  toggleFulfillmentModal: globalActions.toggleFulfillmentModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default compose(connector, withRouter)(CartReconciliation) as React.ComponentType<{
  menuCategories: Category[];
  menuLoading: boolean;
}>;
