import { sdkUtils, type Category, type MenuProduct } from '@koala/sdk';
import { useState, useEffect } from 'react';
import {
  StyledMenuSearchContainer,
  StyledMenuSearchInputContainer,
  StyledClearSearch,
  StyledSearchResults,
  StyledNoResults,
  StyledMagnifyingGlass,
  StyledSearchResult,
  StyledSearchResultImage,
  StyledMenuSearchContainerHeader,
  StyledCancelButton,
} from './styles';
import StringAccessor from '@/components/cmsConfig/stringAccessor';
import { FilledXSvg } from '@/components/uielements/X';
import { GenericFocusTrap } from '@/components/uielements/genericFocusTrap';
import { HideOnScroll } from '@/components/uielements/hideOnScroll';
import { MagnifyingGlassSvg } from '@/components/uielements/magnifyingGlass';
import { StyledSearchInput } from '@/components/uielements/searchInput';
import { K_ANALYTICS_EVENTS } from '@/constants/events';
import { ORDER } from '@/constants/styles';
import { useMenu } from '@/features/menu/service';
import { useSelector } from '@/redux';
import { useConfigOverride } from '@/redux/cmsConfig';
import { selectConveyance } from '@/redux/conveyanceMode/reducer';
import { fireGaEvent, gaActions, gaCats } from '@/utils/googleAnalytics';
import { getProductImageSrc } from '@/utils/imageHelper';
import { fireKAnalyticsEvent } from '@/utils/koalaAnalytics';
import { safelyGetString } from '@/utils/stringHelpers';

interface Props {
  onSelect: (product: MenuProduct, category: Category) => void;
  isMobile?: boolean;
}

interface MenuProductDictionaryEntry {
  product: MenuProduct;
  category: Category;
}

export const MenuSearch = ({ onSelect, isMobile }: Props) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [productDictionary, setProductDictionary] = useState<MenuProductDictionaryEntry[]>([]);
  const [matches, setMatches] = useState<{ item: MenuProductDictionaryEntry }[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);

  const { detail } = useSelector((state) => state.app.locations);
  const strings = useSelector((state) => state.app.cmsConfig.strings);
  const { time_wanted } = useSelector(selectConveyance);
  const { food_hall } = useConfigOverride('admin');
  const { categories, status } = useMenu({
    id: detail?.id,
    wantedAt: time_wanted,
  });

  const filteredCategories = categories.filter((category: Category) => category.products.length);

  useEffect(() => {
    const productDict = sdkUtils.createMenuProductDictionary(filteredCategories, food_hall);
    setProductDictionary(productDict);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      searchAnalytics();
    }, 1000);

    return () => clearTimeout(searchTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, matches]);

  useEffect(() => {
    setIsTyping(true);

    const typingTimeout = setTimeout(() => {
      findMatches(searchTerm);
      setIsTyping(false);
    }, 500);

    return () => {
      clearTimeout(typingTimeout);
      setIsTyping(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const searchAnalytics = () => {
    if (!searchTerm) {
      return;
    }

    fireGaEvent(gaCats.browse, gaActions.menuSearch, { label: searchTerm });
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.MENU_SEARCH, {
      name: searchTerm,
      details: matches.map((match) => match?.item.product.name).join(),
    });
  };

  const handleSearch = (searchString: string) => {
    setSearchTerm(searchString);
  };

  const findMatches = (searchString: string) => {
    const newMatches =
      searchString.length > 1 ? sdkUtils.findFuzzyMenuMatches(productDictionary, searchString) : [];
    setMatches(newMatches);
  };

  const triggerModal = () => {
    isMobile && !modalOpen && setModalOpen(true);
  };

  if (status !== 'success') {
    return null;
  }

  return (
    <GenericFocusTrap
      active={modalOpen}
      focusTrapOptions={{
        clickOutsideDeactivates: true,
      }}
    >
      <StyledMenuSearchContainer $modalOpen={modalOpen}>
        <StyledMenuSearchContainerHeader>
          <StyledMenuSearchInputContainer>
            <StyledSearchInput
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                triggerModal();
                handleSearch(e.target.value);
              }}
              onClick={() => triggerModal()}
              placeholder={safelyGetString(strings, 'store.search_placeholder')}
              value={searchTerm}
            />

            {searchTerm ? (
              <StyledClearSearch
                onClick={() => handleSearch('')}
                aria-label="Clear Search"
                $isVisible={searchTerm}
              >
                <FilledXSvg />
              </StyledClearSearch>
            ) : (
              <StyledMagnifyingGlass>
                <MagnifyingGlassSvg size={17} />
              </StyledMagnifyingGlass>
            )}
          </StyledMenuSearchInputContainer>

          {modalOpen && (
            <StyledCancelButton
              onClick={() => {
                handleSearch('');
                setModalOpen(false);
              }}
              $buttonTheme="knockout"
              $minWidth={0}
              // @IMPLEMENTATION-TODO - add string?
            >
              Cancel
            </StyledCancelButton>
          )}
        </StyledMenuSearchContainerHeader>

        <HideOnScroll
          disabled={modalOpen}
          style={{
            zIndex: ORDER.MENU_SEARCH_RESULTS,
          }}
        >
          <>
            {/* Display results */}
            {matches.length > 0 && (
              <StyledSearchResults>
                {matches.map((match) => {
                  const productImage = getProductImageSrc(match.item.product, {
                    height: 50,
                  });

                  return (
                    <li key={match.item.product.id}>
                      <StyledSearchResult
                        onClick={() => {
                          handleSearch('');
                          setModalOpen(false);
                          onSelect(match.item.product, match.item.category);
                        }}
                      >
                        {productImage && <StyledSearchResultImage backgroundImage={productImage} />}

                        {match.item.product.name}
                      </StyledSearchResult>
                    </li>
                  );
                })}
              </StyledSearchResults>
            )}

            {/* No results */}
            {searchTerm.length > 1 && matches.length === 0 && !isTyping && (
              <StyledSearchResults>
                <StyledNoResults aria-live="polite">
                  <StringAccessor
                    accessor="store.search_no_results_header"
                    tag="h2"
                    dataObj={{
                      searchTerm,
                    }}
                    disableDataObjEncode={true}
                  />

                  <StringAccessor accessor="store.search_no_results_subheader" tag="p" />
                </StyledNoResults>
              </StyledSearchResults>
            )}
          </>
        </HideOnScroll>
      </StyledMenuSearchContainer>
    </GenericFocusTrap>
  );
};
