import { useTheme } from 'styled-components';
import { getFontStyles } from '@/redux/cmsConfig/utils';

interface Props {
  color?: string;
}

// @DESIGN-IMPLEMENTATION-TODO - add additional chevrons and size variants, deprecate old chevron

const _ChevronDown = ({ color }: Props) => {
  const theme = useTheme();
  const { color: fallbackColor } = getFontStyles(theme.modals.font);
  return (
    <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.58638 9.74342L15.785 1.55458C16.0791 1.19608 16.0704 0.637421 15.7654 0.290927C15.4603 -0.0555668 14.9685 -0.065424 14.6529 0.268631L8.00964 7.81449L1.36639 0.268631C1.2169 0.0967426 1.01304 0 0.800329 0C0.587615 0 0.383757 0.0967426 0.234265 0.268631C-0.0780888 0.623866 -0.0780888 1.19935 0.234265 1.55458L7.47561 9.74342C7.78462 10.0855 8.27738 10.0855 8.58638 9.74342Z"
        fill={color ?? fallbackColor}
      />
    </svg>
  );
};

export const ChevronDown = _ChevronDown;
