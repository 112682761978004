import styled from 'styled-components';
import { getFontStyles } from '@/redux/cmsConfig/utils';

interface Props {
  expand?: boolean;
}

export const StyledReorderViewAllContainer = styled.div<Props>`
  ${({ expand }) => `
    --reorder-view-all-container__flex-grow: ${expand ? '1' : 'auto'};
  `}

  flex-grow: var(--reorder-view-all-container__flex-grow);
  margin: 0 0 0 auto;
`;

export const StyledReorderViewAll = styled.a(({ theme }) => ({
  textDecoration: 'underline',
  ...getFontStyles(theme.text.primary_font, ['color']),
}));
