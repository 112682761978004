import { dehydrate, type QueryClient } from '@tanstack/react-query';
import { type SSRData } from 'next-urql';

export interface ServerQuery {
  queryKey: unknown[];
  queryFn: Function;
}

/**
 * Fetches required react-query requests on the server
 * #### Example request:
 * ```ts
 * queryOnServer([{
 *  queryKey: ["site-footer"],
 *  queryFn: getFooterTemplate
 * }])
 * ```
 * @param locationId a store location id
 * @param maxItems a maximum amount of items (api maximum is 50)
 * @returns `Promise<DehydratedState>`
 */
export async function queryOnServer(queries: ServerQuery[], queryClient: QueryClient) {
  // this allows us to map over each query and wait for all of them to resolve
  // we need all of our queries to resolve before we send the dehydrated state
  // *ideally* our routes are all heavily cached so we don't create blocking network requests on every page load
  await Promise.all(
    queries.map(function ({ queryKey, queryFn }) {
      // @ts-expect-error: queryFn is a function, as typed in `ServerQuery`
      return queryClient.prefetchQuery(queryKey, queryFn, {
        staleTime: 60000,
      });
    }),
  );

  return dehydrate(queryClient);
}

export function extractUrqlState(ssrData: SSRData): SSRData {
  const urqlState = ssrData;
  const urqlStateId = Object.keys(urqlState)[0];
  urqlState[urqlStateId].data = urqlState[urqlStateId].data ?? '';
  return urqlState;
}
