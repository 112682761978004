import { sdkUtils } from '@koala/sdk';
import Head from 'next/head';
import { useMenu } from '@/features/menu/service';
import { useSelector } from '@/redux';
import { selectConveyance } from '@/redux/conveyanceMode/reducer';
import { type StoreRouteParams } from '@/utils/locations';
import { interpolateIntoString } from '@/utils/stringHelpers';

interface Props {
  routeParams: StoreRouteParams;
}

/**
 * Adds some store-specific content to the document's `<head>`.
 */
export const StoreHead = ({ routeParams }: Props) => {
  const { detail, ...locations } = useSelector((state) => state.app.locations);
  const { time_wanted } = useSelector(selectConveyance);
  const { categories } = useMenu({
    id: detail?.id,
    wantedAt: time_wanted,
  });

  const { seo } = useSelector((state) => state.app.cmsConfig.webConfig);

  // Assemble some data to build the meta tags.
  const locationsLastUpdatedAt = locations.meta.lastUpdatedAt!;
  const dataObject = {
    label: detail?.label,
    city: detail?.cached_data?.city,
    state: detail?.cached_data?.state,
    categoryName: routeParams.catName,
  };

  /**
   * We're on the Product Detail page if the productId param is present.
   */
  if (routeParams.productId) {
    // Find the selected product.
    const product = sdkUtils.findMenuItemById(routeParams.productId, categories);

    // Assemble a data object for interpolation.
    const data = {
      ...dataObject,
      productName: product?.name,
      productDescription: product?.description,
      productImage:
        product?.image && product?.image === product?.images?.image_url_1_by_1
          ? product?.image
          : product?.images?.image_url_1_by_1,
    };

    // Page title and description overrides.
    const { product_page_title, product_page_description } = seo;
    const title = interpolateIntoString(product_page_title, data, true);
    const desc = interpolateIntoString(product_page_description, data, true);
    return (
      <Head>
        <meta
          name="location:last-updated-at"
          content={locationsLastUpdatedAt}
          key="location-last-updated-at"
        />
        {product_page_title && (
          <>
            <title>{title}</title>
            <meta property="og:title" key="og:title" content={title} />
            <meta name="twitter:title" key="twitter:title" content={title} />
          </>
        )}
        {product_page_description && (
          <>
            <meta name="description" content={desc} key="description" />
            <meta property="og:description" key="og:description" content={desc} />
            <meta name="twitter:description" key="twitter:description" content={desc} />
          </>
        )}
        {data.productImage && (
          <>
            <meta property="og:image" key="og:image" content={data.productImage} />
            <meta name="twitter:image" key="twitter:image" content={data.productImage} />
          </>
        )}
      </Head>
    );
  }

  /**
   * We're on the Store page if the productId param is absent.
   */
  // Page title and description overrides.
  const { store_page_title, store_page_description } = seo;
  const title = interpolateIntoString(store_page_title, dataObject, true);
  const desc = interpolateIntoString(store_page_description, dataObject, true);
  return (
    <Head>
      <meta
        name="location:last-updated-at"
        content={locationsLastUpdatedAt}
        key="location-last-updated-at"
      />
      {store_page_title && (
        <>
          <title>{title}</title>
          <meta property="og:title" key="og:title" content={title} />
          <meta name="twitter:title" key="twitter:title" content={title} />
        </>
      )}
      {store_page_description && (
        <>
          <meta name="description" content={desc} key="description" />
          <meta property="og:description" key="og:description" content={desc} />
          <meta name="twitter:description" key="twitter:description" content={desc} />
        </>
      )}
    </Head>
  );
};
