import { type Category } from '@koala/sdk/v4';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  StyledFilterTagListItem,
  StyledFilterTag,
  StyledFilterTagImage,
  StyledFilterTagList,
  StyledTagLabel,
} from './styles';
import { genericEventHandler } from '@/analytics/events';
import { GlobalEvents } from '@/analytics/events/constants';
import { useFoodHalls } from '@/features/food-halls/service';
import tagsActions from '@/redux/tags/actions';
import { type RootState } from '@/types/app';
import { resizeImage } from '@/utils/imageHelper';

interface Props {
  menuCategories: Category[];
}
export function FilterNavigation({ menuCategories }: Props) {
  const { activeTagIds } = useSelector((state: RootState) => state.app.tags);
  const dispatch = useDispatch();
  const router = useRouter();
  const filterTags = useFoodHalls(menuCategories);

  const shouldTagsCenter = filterTags?.length <= 3 ? true : false;

  const setActiveFilter = (): void => {
    if (activeTagIds) {
      return;
    }

    let filter = '';

    // Find filter tag in url query
    if (router.query.filter) {
      filter = router.query.filter.toString();
    }

    // Find tag by label or id
    const activeTag =
      filterTags.find(
        (tag) => tag.label === decodeURIComponent(filter) || tag.id === Number(filter),
      ) ?? filterTags[0];

    // Set active tag (<RouteHandler /> takes care of appending the URL query parameters)
    if (activeTag?.id) {
      dispatch(tagsActions.setActiveTag(activeTag));
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(setActiveFilter, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(setActiveFilter, [filterTags]);

  return !activeTagIds || !filterTags.length ? null : (
    <StyledFilterTagList centeredTags={shouldTagsCenter}>
      {filterTags.map((tag, index) => (
        <StyledFilterTagListItem key={`${tag.label}-${index}`}>
          <StyledFilterTag
            onClick={() => {
              // Set active tag (<RouteHandler /> takes care of appending the URL query parameters)
              dispatch(tagsActions.setActiveTag(tag));

              genericEventHandler(GlobalEvents.GENERIC__CTA, {
                name: tag.label,
                details: 'Filter Tag',
              });
            }}
          >
            <StyledFilterTagImage $active={activeTagIds.includes(tag.id)}>
              {tag.icon_image_url ? (
                <img src={resizeImage(tag.icon_image_url, { height: 100 })} alt={tag.label} />
              ) : (
                <StyledTagLabel>{tag.label}</StyledTagLabel>
              )}
            </StyledFilterTagImage>
          </StyledFilterTag>
        </StyledFilterTagListItem>
      ))}
    </StyledFilterTagList>
  );
}
