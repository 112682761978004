import { type Location, type WebConfig } from '@koala/sdk';
import { LOCATION_ATTRIBUTE_POSITIONS } from '@/constants/locations';
import { resizeImage } from '@/utils/imageHelper';
import { safelyGetConfig } from '@/utils/stringHelpers';

export const getLocationHeroBackground = (
  locationDetail: Location | undefined,
  webConfig: WebConfig,
  resize = true,
) => {
  if (!locationDetail) {
    return null;
  }

  let imageSrc = safelyGetConfig(webConfig, 'store_detail.background_image') as unknown as string;

  if (locationDetail.attributes) {
    const heroAttribute = locationDetail.attributes.find(
      (attribute) => attribute?.position?.id === LOCATION_ATTRIBUTE_POSITIONS.STORE_DETAIL_HERO,
    );

    if (heroAttribute) {
      imageSrc = heroAttribute.value as string;
    }
  }

  return resize ? resizeImage(imageSrc, { width: 2000 }) : imageSrc;
};
