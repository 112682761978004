import styled from 'styled-components';
import StringAccessor from '@/components/cmsConfig/stringAccessor';
import { useSelector } from '@/redux';

export function Disclaimers() {
  const { locations } = useSelector((state) => state.app);

  return (
    <Container>
      <StringAccessor
        tag="p"
        accessor="store.menu_disclaimer"
        html={true}
        dataObj={locations.detail}
      />

      {locations.detail?.disclaimers.map((disclaimer, index) => (
        <Text
          key={`${disclaimer.name}-${index}`}
          dangerouslySetInnerHTML={{
            __html: disclaimer.description?.replace(/(?:\r\n|\r|\n)/g, '<br /><br />'),
          }}
        />
      ))}
    </Container>
  );
}

export const Container = styled.div`
  margin: 0 auto;
  max-width: var(--size-4xl);
`;

const Text = styled.p({
  overflowWrap: 'break-word',
  wordBreak: 'break-all',
});
