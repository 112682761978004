import { type WebConfig } from '@koala/sdk';
import { readableColor } from 'polished';
import styled from 'styled-components';
import { StyledPrimaryButton } from '@/components/uielements/primaryButton/styles';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import { safePolished } from '@/utils/safePolished';

interface ThemeProps {
  theme: WebConfig;
}

export const StyledReorderListItem = styled.li`
  border: 1px solid ${(props: ThemeProps) => props.theme.global.primary_border_color};
  border-radius: ${(props: ThemeProps) => props.theme.global.border_radius}px;
  background-color: ${(props: ThemeProps) => props.theme.location_card.background_color};
  display: flex;
  flex-direction: column;
  list-style-type: none;
  min-width: 265px;
  padding: var(--size-3);
  scroll-snap-align: start;
  width: auto;

  @media (min-width: 768px) {
    margin: 0;
    width: 33.3333%;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const ReorderHeader = styled.header`
  display: flex;
  justify-content: space-between;
`;

export const StyledReorderDate = styled.h3(({ theme }) => ({
  margin: 0,
  ...getFontStyles(theme.location_card.secondary_font, ['color', 'font_size']),
}));

export const StyledReorderConveyanceLabel = styled.span(({ theme }) => ({
  backgroundColor: theme.buttons.primary_button_background_color,
  borderRadius: `${theme.buttons.primary_button_border_radius}px`,
  fontSize: '12px',
  padding: 'var(--size-1) var(--size-2)',
  whiteSpace: 'nowrap',
  ...getFontStyles(theme.buttons.primary_font, ['font_family', 'font_weight', 'color'], {
    color: (value) => {
      if (value === 'revert' || value === null) {
        return safePolished(readableColor)(theme.buttons.primary_button_background_color);
      }

      return value;
    },
  }),
}));

export const StyledReorderLocationLabel = styled.span`
  display: block;
  font-size: 13px;
  margin: var(--size-2) 0 0;
`;

export const StyledReorderMenuItems = styled.div`
  flex-grow: 1;
  margin: var(--size-3) 0 0;

  p {
    margin: 0;

    @supports (-webkit-line-clamp: 2) {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
`;

export const StyledReorderButton = styled(StyledPrimaryButton)`
  margin: var(--size-3) 0 0;
  width: 100%;
`;
