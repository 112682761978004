import styled from 'styled-components';
import { getLocationHeroBackground } from './utils';
import { useSelector } from '@/redux';

export const StoreHero = () => {
  const webConfig = useSelector((state) => state.app.cmsConfig.webConfig);
  const locationDetail = useSelector((state) => state.app.locations.detail);
  const background = getLocationHeroBackground(locationDetail, webConfig);

  return background ? <StoreBanner src={background} alt="Location hero image" /> : null;
};

const StoreBanner = styled.img(({ theme }) => ({
  backgroundColor: theme.store_detail.background_color ?? 'transparent',
  display: 'none',
  height: `${theme.store_detail.hero_height}px`,
  objectFit: 'cover',
  width: '100%',

  '@media (min-width: 768px)': {
    display: 'block',
  },
}));
